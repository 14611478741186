import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import Content, { NavBar } from './routes'
import store from './redux';
import './App.css';
//import logo from './logo.svg';

function App() {
  function storeKey() {
    const keyInput = document.getElementById('key') as HTMLInputElement;
    sessionStorage.setItem("sessionKey", keyInput.value);    
  }

  return (
    <Provider store={store}>
        <Router>
          <div className="sjs-client-app">
            <header className="sjs-client-app__header">
              <span style={{fontWeight:'bold', fontSize: '1.5em'}}>Pharmecist</span>
              <NavBar/>
              <span style={{ float: 'right' }}>
                <input type="text" id="key"></input>
                <button onClick={storeKey}>Set Key</button>
              </span>
            </header>
            <main className="sjs-client-app__content">
              <Content/>
            </main>
            <footer className="sjs-client-app__footer">
            </footer>
          </div>
        </Router>
    </Provider>
  );
}

export default App;
